window.bootstrap = require('bootstrap');
// import {fade} from './fade';

window.addEventListener('load', function () {
  document.body.style.opacity = 1;
  const topLogo = document.getElementById('left-logo');
  const homeNav = document.getElementById('mainNav');
  const pageNav = document.getElementById('secondaryNav');
  const navbarToggler = document.body.querySelector('.navbar-toggler');
  const navCollapse = document.querySelector('.navbar-collapse');
  var collapseButtons = document.querySelectorAll(".accordion-button");
  // var navbarHeight = homeNav.clientHeight;
  
  collapseButtons.forEach(function (current) {
    current.addEventListener("click", function () {
      var item = current.closest(".accordion-item");
      if (current.classList.contains("collapsed")) return;
      var menu = document.getElementById("accordionMenu");
      menu.addEventListener('hide.bs.collapse', function (e) {
        e.preventDefault;
      });
      menu.addEventListener("shown.bs.collapse", function () {
        window.scrollTo({
          top: item.offsetTop - 45,
          behavior: "smooth",
        });
      });
    });

  });  

  window.addEventListener('hashchange', function () {
    var uri = window.location.toString();

    if (uri.indexOf("#") > 0) {
      var clean_uri = uri.substring(0,
        uri.indexOf("#"));

      window.history.replaceState({},
        document.title, clean_uri);
    }
  });  

  function brandShow() {
    if (homeNav && window.scrollY > 300) {
      topLogo.style.opacity = '1';
      homeNav.classList.add('solid');
      return;
    } else if (homeNav && window.scrollY < 300 && !navCollapse.classList.contains('show')) {
      topLogo.style.opacity = '0';
      homeNav.classList.remove('solid');
      return;        
    }
    if (pageNav) {
        topLogo.style.opacity = '1';
        return;
      }
  }

    // Show/Hide Navbar Brand 
    brandShow();

    // Show/Hide Navbar Brand when page is scrolled
  document.addEventListener('scroll', brandShow);

    // Collapse responsive navbar when toggler is visible
    navbarToggler.addEventListener('click', function () {
      topLogo.style.opacity = '1';
      if (homeNav) {
        homeNav.classList.add('solid');
      } else {
        return;
      }
    });
    const responsiveNavItems = [].slice.call(
      document.querySelectorAll('#menu-top-main .nav-link')
    );
    responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener('click', () => {
        if (window.getComputedStyle(navbarToggler).display !== 'none') {
          navbarToggler.click();
        }
      });
    });

    navCollapse.addEventListener('shown.bs.collapse', function () {
      if (homeNav && window.scrollY < 300) {
        topLogo.style.opacity = '1';
      }
    });
    navCollapse.addEventListener('hidden.bs.collapse', function () {
      if (homeNav && window.scrollY < 300) {
        homeNav.classList.remove('solid');
        topLogo.style.opacity = '0';
      }
    });

    var TIMEOUT = null;
    window.addEventListener("resize", function () {
      if (TIMEOUT === null) {
        TIMEOUT = window.setTimeout(function () {
          TIMEOUT = null;
          //fb_iframe_widget class is added after first FB.FXBML.parse()
          //fb_iframe_widget_fluid is added in same situation, but only for mobile devices (tablets, phones)
          //By removing those classes FB.XFBML.parse() will reset the plugin widths.
          document.getElementById("fb-widget").classList.remove("fb_iframe_widget");
          document.getElementById("fb-widget").classList.remove("fb_iframe_widget_fluid");
          FB.XFBML.parse();
        }, 300);
      }
    });
  })
